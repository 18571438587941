///////////////////////////////////////////////////
/* TABLES */
///////////////////////////////////////////////////
table a {
  color: $info;
}

.channel-row-first-col {
  width: 178px;
}

/* fix to keep buttons the same height as column header titles */
table th .btn {
  height: auto;
  line-height: inherit !important;
  gap: 0;
}
/* fix to keep sorting buttons centered if the th has text-center class */
table th.text-center .btn {
  margin: 0 auto;
}

/* fix to add extra starting space to 1st and last cell of each row or heading row */
tr td:first-child,
tr th:first-child {
  padding-left: 1.5rem;
}

tr td:last-child,
tr th:last-child {
  padding-right: 1.5rem;
}

///////////////////////////////////////////////////
/* AdvancedView (table tab) */
///////////////////////////////////////////////////
.AdvancedView {
  background: $gray-700;
  color: $white;
  width: 100%;
  }

///////////////////////////////////////////////////
/* table-content-type */
///////////////////////////////////////////////////
.table-content-type { 
  text-transform: capitalize; min-width: 280px;
  .title { color: $dark;}
  .subtitle { color: $gray-500;}
  .title, .subtitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;  
  }
  .iconfeatured, .title, .subtitle {transition: color .2s ease-in-out}
}
a.table-content-type {
  &:hover {
    text-decoration: none; 
    .title { color: $primary;}
    .iconfeatured { color: $primary; border-color: $primary-300;}
  }
}


///////////////////////////////////////////////////
/* Table centered (Custom) */
///////////////////////////////////////////////////
.table-centered {

  th,
  td {
    vertical-align: middle !important;
  }
}


///////////////////////////////////////////////////
/* Custom table components (Custom) */
///////////////////////////////////////////////////
.table {
  .table-user {
    img {
      height: 30px;
      width: 30px;
    }
  }

  .action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
      color: $gray-700;
    }
  }
}

///////////////////////////////////////////////////
/* Tables fluid */
///////////////////////////////////////////////////
.table-nowrap {

  th,
  td {
    white-space: nowrap;
  }
}


table .form-check {
  padding-left: 0;
}

table .form-check .form-check-input {
  margin-left: 0;
}


.table-responsive {
  // background:$gray-200;
  box-shadow: 0 0.375rem 0.75rem rgba(21, 21, 21, 0.075);
}

.table-responsive table {
  // width: calc(100% - 2px);
  margin-bottom: 0;
}

.col-min-3buttons {
  min-width: 190px;
}

.col-min-2buttons {
  min-width: 140px;
}

.table> :not(caption)>*>* {
  border-bottom-color: $gray-300;
}

.form-switch.bg-dark .form-check-input {
  background-color: $gray-600;
  border: 1px solid $gray-600;
}

.form-switch.bg-dark .form-check-input:checked {
  background-color: $primary;
  border: 1px solid $primary;
}

.hidden-col {
  background-color: $gray-700 !important;
  transition: all 0.5s ease-in-out;
}

/* make columns min width */
// table { width: inherit !important; }
tbody {
  font-size: 0.85rem;
}

th {
  width: auto;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;

  button {
    font-family: 'Oswald', sans-serif !important;
    text-transform: uppercase;
    color: white !important;

    &:focus,
    &:focus-visible,
    &:focus-within {
      outline: none !important;
      box-shadow: none !important;
    }

    &:hover {
      color: $primary-300 !important;
    }
  }
}

td {
  width: auto;
  // border: 1px solid rgba(0, 0, 0, 0.15) !important;
  vertical-align: middle;
}

td.mincol,
th.mincol {
  width: 1% !important;
  white-space: nowrap !important;
}

td.maxcol,
th.maxcol {
  max-width: 300px !important;
  min-width: 300px !important;
  white-space: wrap !important;
}

.table-arrows {
  height: 11px!important;
  margin-left: 4px;

  .arr-top {
    fill: white;
  }

  .arr-bot {
    fill: white;
  }
}

.col-ordered-down {
  .arr-top {
    fill: $primary-300;
  }
  
  .arr-bot {
    fill: $white;
  }
}

.col-ordered-up {
  .arr-top {
    fill: $white;
  }
  
  .arr-bot {
    fill: $primary-300;
  }
}


///////////////////////////////////////////////////
/* StickyHeader */
///////////////////////////////////////////////////
.sticky-table-header {
  max-height: 66vh; overflow: auto;
  .sticky-header {
    position: sticky; top: 0; z-index: 1000; box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.5)
  }
}

///////////////////////////////////////////////////
/* Overflow X */
///////////////////////////////////////////////////
.gradient-button {
  background: linear-gradient(270deg, rgba(33, 33, 33, 0.75), rgba(33, 33, 33, 0));
  svg { filter: drop-shadow(0px 1px 4px rgba(0,0,0,0.25)) }
}

.gradient-button:hover {
  background: linear-gradient(270deg, rgba(33, 33, 33, .85), rgba(33, 33, 33, 0));
  svg { filter: drop-shadow(0px 2px 4px rgba(0,0,0,0.75)) }
}